@import url("https://fonts.googleapis.com/css?family=Raleway");

@import "variables"; // custom variables to override bootstrap values
@import "~bootstrap/scss/bootstrap"; // bootstrap styles

//@import "~open-iconic/font/css/open-iconic-bootstrap.css";
$icon-font-path: '../../node_modules/open-iconic/font/fonts/';
@import "../../node_modules/open-iconic/font/css/open-iconic-bootstrap.scss";
//@import "~datatables.net-bs4/css/dataTables.bootstrap4";

@import "custom"; // custom styles