html, body {
  overflow-x: hidden; /* Prevent scroll on narrow devices */
}
html {
  position: relative;
  min-height: 100%;
}
body {
  /* Margin bottom by footer height */
  margin-bottom: $footer-height;


  font-family: 'Raleway', sans-serif;
  background: radial-gradient(
                  rgba(0, 0, 0, 0),
                  rgba(0, 0, 0, 0.5)
  ),
  url("../../resources/website-background-christmas.jpg");
  background-size: cover;
}

.text-big {
  font-size: 1.25rem;
}

.text-bigger {
  font-size: 1.75rem;
}

.blockquote {
  font-size: 1.1rem;
}

/*blockquote p:before {
  content: open-quote;
}
blockquote p:after {
  content: close-quote;
  float: right;
}

blockquote p:before, blockquote p:after {
  font-weight: bold;
  font-size: 2rem;
}*/

.navbar-nav li a {
  position: relative;
}

.navbar-nav li a.active::before {
  content: "\A";
  border: 6px solid transparent;
  border-bottom-color: #fff;
  position: absolute;
  bottom: -8px;
  left: calc(50% - 6px);
}

.navbar .navbar-image {
  height: 30px;
}

.dropdown-menu-user {
  border-radius: 0;
  margin: 0;
}

.system-msgs {
  margin-bottom: 0;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 60px;
  line-height: 60px; /* Vertically center the text there */
  background-color: #f5f5f5;
  span.version {
    float: right;
  }
}

.border-round, .border-round-sm {
  border-radius: 5px;
}

.light-transparent {
  background-color: rgba($light, .5);
}

.white-filler {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: -999;

  padding-top: 90px;
  .filler-inner {
    background-color: $light;
    height: 100%;
    opacity: 0.9;
    &.border-round-sm {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.h-image {
  height: 30px;
}

textarea.textarea-transparent {
  resize: none;
}

.form-control, .textarea-transparent {
  background-color: rgba($light, .5);
}

#set-wish-success .oi, #set-notification-success .oi {
  vertical-align: middle;
  font-size: 1.2rem;
}

$globe-width:   12px;
$globe-height:  28px;
$globe-spacing: 40px;
$globe-spread: 3px;
$light-off-opacity: 0.4;

.lightrope {
  display: none;
}

body.lights-on {
  .navbar {
    margin-top: 50px !important;
  }

  .white-filler {
    padding-top: 125px;
  }

  .lightrope {
    display: block;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 0;
    margin: -15px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;

    li {
      position: relative;
      animation-fill-mode: both;
      animation-iteration-count: infinite;
      list-style: none;
      margin: 0;
      padding: 0;
      width: $globe-width;
      height: $globe-height;
      border-radius: 50%;
      margin: $globe-spacing/2;
      display: inline-block;
      background: rgba(0, 247, 165, 1);
      box-shadow: 0 $globe-height/6 $globe-width*2 $globe-spread rgba(0, 247, 165, 1);
      animation-name: flash-1;
      animation-duration: 2s;

      &:nth-child(2n+1) {
        background: rgba(0, 255, 255, 1);
        box-shadow: 0 $globe-height/6 $globe-width*2 $globe-spread rgba(0, 255, 255, 0.5);
        animation-name: flash-2;
        animation-duration: 0.4s;
      }

      &:nth-child(4n+2) {
        background: rgba(247, 0, 148, 1);
        box-shadow: 0 $globe-height/6 $globe-width*2 $globe-spread rgba(247, 0, 148, 1);
        animation-name: flash-3;
        animation-duration: 1.1s;
      }

      &:nth-child(odd) {
        animation-duration: 1.8s;
      }

      &:nth-child(3n+1) {
        animation-duration: 1.4s;
      }

      &:before {
        content: "";
        position: absolute;
        background: #222;
        width: ($globe-width - 2);
        height: $globe-height/3;
        border-radius: 3px;
        top: (0 - ($globe-height/6));
        left: 1px;
      }

      &:after {
        content: "";
        top: (0 - $globe-height/2);
        left: $globe-width - 3;
        position: absolute;
        width: $globe-spacing + 12;
        height: ($globe-height/3 * 2);
        border-bottom: solid #222 2px;
        border-radius: 50%;
      }

      &:last-child:after {
        content: none;
      }

      &:first-child {
        margin-left: -$globe-spacing;
      }
    }
  }
}

@keyframes flash-1 {
  0%, 100% { background: rgba(0,247,165,1);
    box-shadow: 0 $globe-height/6 $globe-width*2 $globe-spread rgba(0,247,165,1);}
  50% { background: rgba(0,247,165,$light-off-opacity);
    box-shadow: 0 $globe-height/6 $globe-width*2 $globe-spread rgba(0,247,165,0.2);}
}
@keyframes flash-2 {
  0%, 100% { background: rgba(0,255,255,1);
    box-shadow: 0 $globe-height/6 $globe-width*2 $globe-spread rgba(0,255,255,1);}
  50% { background: rgba(0,255,255,$light-off-opacity);
    box-shadow: 0 $globe-height/6 $globe-width*2 $globe-spread rgba(0,255,255,0.2);}
}
@keyframes flash-3 {
  0%, 100% { background: rgba(247,0,148,1);
    box-shadow: 0 $globe-height/6 $globe-width*2 $globe-spread rgba(247,0,148,1);}
  50% { background: rgba(247,0,148,$light-off-opacity);
    box-shadow: 0 $globe-height/6 $globe-width*2 $globe-spread rgba(247,0,148,0.2);}
}


@include media-breakpoint-down(sm) {
  .white-filler {
    padding-top: 66px;
  }
  .navbar-nav li a.active::before {
    display: none;
  }
}

@include media-breakpoint-down(xs) {
  .border-round-sm {
    border-radius: 0;
  }
  body.lights-on {
    .navbar {
      margin-top: 0 !important;
      margin-bottom: 56px !important;
    }
    .white-filler {
      padding-top: 106px;
    }
    .lightrope {
      top: 56px;
    }
  }
}

@include media-breakpoint-up(md) {
  ul.navbar-nav li.dropdown:hover > .dropdown-menu {
    display: block;
  }
}